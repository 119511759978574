.on-ramp-select {
  border: 1px solid !important;
  border-radius: 8px !important;
  max-width: 200px !important;
  width: 200px !important;
  height: 40px !important;
}

.on-ramp-select-option {
  background-color: white;
  color: black;
  font-size: 18px;
  height: 40px;
  padding: 18px;
  margin-left: 2px;
}

.on-ramp-select option span.on-ramp-name {
  font-weight: bold;
  font-size: 16px;
}

.on-ramp-select option span.email {
  font-size: 12px;
  display: block;
  margin-top: 4px;
}

.filter-section {
  max-height: 1000px;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out; 
}

.filter-section.collapsed {
  max-height: 0;
  padding: 0;
}

.expanded-row {
  background-color: #f5f5f5;
  padding: 10px;
}

.notes-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.notes-list li {
  margin-bottom: 5px;
}

.no-notes {
  color: #999;
  text-align: center;
  padding: 10px;
}