.has-wrapper {
  position: relative;

  .has-input {
    border: none;
  }

  .has-icon {
    position: absolute;
    right: -0px;
    margin-right: 5px;
    top: 8px;
    font-size: 20px;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.login-input {
  display: block;
  width: 100%;
}

.icon {
  position: absolute;
  right: 1px;
  top: 4px;
  font-size: 20px;
  text-align: center;
}

.border-dark {
  border-color: #181b30;
}

.border-radius {
  border-radius: 48px;
}
