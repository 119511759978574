.create-new-application {
  transition: all 0.15s ease-in-out;
}

.create-new-application:hover {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    scale: 1.01;
}

.hidden-text {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.15s, opacity 0.15s linear;
  /* small grey text */
  font-size: 0.9rem !important;
  color: #6c757d !important;
  line-height: 1.2rem !important;
  margin: 0 auto !important;
}

.create-new-application:hover .hidden-text {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.new-user-account-type:hover .hidden-text-account {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.new-user-account-type:hover .account-type-name {
  display: none;
}

.account-type-name {
  display: block;
}

.hidden-text-account {
  font-size: 10px !important;
  visibility: hidden;
  opacity: 0;
  /* transition: visibility 0s 0.15s, opacity 0.15s linear; */
  margin: 0 auto !important;
  padding-left: 2px;
  padding-right: 2px;
}
