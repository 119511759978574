//
// _table.scss
//

.table {
  th {
    font-weight: 500;
    border-top: none;
  }
}

//Table centered
.table-centered {
  td,
  th {
    vertical-align: middle !important;
  }
}

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}
.page-content-table{
  padding: calc(80px + 18px) calc(350px / 2) 60px calc(350px / 2);
}
.searchbar-css {
  width: 18% !important;
}

.visually-hidden{
  display: none;
}
.pagination{
  justify-content: end;
}

.select-page{
  height: 37px !important;
  width: 29%;
  padding: 0.2rem 0.3rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #505d69;
    background-color: #F0F1F266;
    border: 1px solid #EDEEF0;
    border-radius: 0.5rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.page-content-application{
  padding: calc(80px + 18px) calc(20px / 1) 0px calc(8px / 1);
}

// Bussiness User Table Start By Urvashi
.name-label{
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
}
.ticker-label{
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
}
.CircularProgressbar {
  width: 1.5rem !important;
}
.CircularProgressbar .CircularProgressbar-path {
  stroke: rgb(0,82,255) !important;
}
.CircularProgressbar-text{
  display: none;
}
.CircularProgressbar-trail, .CircularProgressbar-path{
  stroke-width:12 !important;
}
.btn-continue{
  font-size: 16px;
    background: #b3b3b354;
    border-color: #b3b3b354;
    width: 9rem;
    border-radius: 25px;
}
.p-column-filter-menu-button{
  display: none !important;
}
.p-tag.p-tag-secondary {
  background-color: #e2e3e5 !important;
  color: #ffffff;
}


.p-datatable-header div:first-of-type {
  display: flex !important;
}

.dropdown-list-image {
  position: relative;
  height: 2.5rem;
  width: 2.5rem;
}
.dropdown-list-image img {
  height: 2.5rem;
  width: 2.5rem;
}


.section-50 {
  padding: 50px 0;
}

.m-b-50 {
  margin-bottom: 50px;
}

.dark-link {
  color: #333;
}

.heading-line {
  position: relative;
  padding-bottom: 5px;
}

.heading-line:after {
  content: "";
  height: 4px;
  width: 75px;
  background-color: #29B6F6;
  position: absolute;
  bottom: 0;
  left: 0;
}

.notification-ui_dd-content {
  margin-bottom: 30px;
}

.notification-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 7px;
  background: #fff;
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.06);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.06);
}

.notification-list--unread {
  border-left: 2px solid #29B6F6;
}

.notification-list .notification-list_content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.notification-list .notification-list_content .notification-list_img img {
  height: 48px;
  width: 48px;
  border-radius: 50px;
  margin-right: 20px;
}

.notification-list .notification-list_content .notification-list_detail p {
  margin-bottom: 5px;
  line-height: 1.2;
}

.notification-list .notification-list_feature-img img {
  height: 48px;
  width: 48px;
  border-radius: 5px;
  margin-left: 20px;
}

.btn-font-text{
  color: white !important;
}
.css-13cymwt-control, .css-t3ipsp-control ,.css-t3ipsp-contro:hover, .css-t3ipsp-contro:active{
  min-height: 48px !important;
  border-radius: 8px !important;
  padding-left: 13px;
  border-color: #181B30 !important;
}

.investor_box{
  border: 1px solid grey;
  border-radius: 8px;
}

.round-info {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 8px;
}

.round-info h3 {
  margin-top: 0;
}

.progress{
  height: 1.2rem !important;
}