.cursor-pointer {
  cursor: pointer !important;
}

// Hover
.hover {
  &\:text-reset {
    &:hover {
      color: inherit !important;
    }
  }
  &\:text-white {
    &:hover {
      color: $white !important;
    }
  }
  &\:text-dark {
    &:hover {
      color: $dark !important;
    }
  }
  &\:bg-gray-100 {
    &:hover {
      background-color: $gray-100 !important;
    }
  }
  &\:bg-gray-900 {
    &:hover {
      background-color: $gray-900 !important;
    }
  }
}

// Line height
// .lh {
//   @each $value in (0, 1, 1.5, 2, 2.5, 3) {
//     &-#{$value} {
//       line-height: #{$value} !important;
//     }
//   }
// }

// Borders
@each $width in (0, 1, 2, 3) {
  .border-width-#{$width} {
    border-width: #{$width}px !important;
  }
}
.border-style-solid {
  border-style: solid !important;
}

@each $value in (0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100) {
  .opacity-#{$value} {
    opacity: $value/100 !important;
  }
  .hover\:opacity-#{$value} {
    &:hover {
      opacity: $value/100 !important;
    }
  }
}

// Size
@each $value in ('min-height', 'height', 'max-height') {
  .#{$value}-100vh {
    #{$value}: 100vh !important;
  }
}

// Position
.position-cover {
  height: auto;
  width: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.pointer-events-none {
  pointer-events: none;
}
