//
// Forms.scss
//

// checkbox input right

.form-check-right {
  padding-left: 0;
  display: inline-block;
  padding-right: 1.5rem;
  .form-check-input {
    height: 1.725rem;
    width: 1rem;
    right: 0;
    margin-left: 0;
  }

  .form-check-label {
    display: block;
  }
}

.custom-control-right {
  padding-left: 0;
  padding-right: 1.5rem;
  display: inline-block;
  .custom-control-label {
    display: inline-block;
    &:before,
    &:after {
      left: auto;
      right: -1.5rem;
    }
  }

  .custom-control-input {
    left: auto;
  }
}

.custom-control-label {
  cursor: pointer;
}

.form-control-search {
  background-image: url(../../../images/icons/search.svg);
  background-repeat: no-repeat;
  background-position: 1.25rem 50%;
  padding-left: 3.5rem;
}
