.switch-toggle {
  position: relative;
  align-self: flex-start;
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
  flex: 1;
}

.label-left,
.label-right {
  user-select: none;
}

.toggle-container {
  position: relative;
  width: 60px;
  height: 30px;
}

.toggle-container input[type='checkbox'] {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 30px;
  background-color: #e9e9e9;
  cursor: pointer;
  border-radius: 30px;
  transition: background-color 0.2s;
}

.toggle-label span {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 26px;
  height: 26px;
  background-color: #ffffff;
  border-radius: 50%;
  transition: 0.3s;
}

.toggle-container input[type='checkbox']:checked + .toggle-label span {
  left: 32px;
}

.holders-table-row:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.active-content {
  background-color: #f5f5f5 !important;
}

.security-content {
  display: flex;
  flex-direction: column;
}

.security-content-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.negative {
  color: red;
}

.positive {
  color: green;
}

.arrow {
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 2.5px;
  border: none !important;
}

.table-of-contents {
  display: flex;
  flex-direction: column;
}

.table-of-contents-title {
  margin: 0 0 1rem;
}

.table-of-contents-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.table-of-contents-list {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  list-style-type: none;
  padding-inline-start: 0px;
}

.table-of-contents-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 1rem;
}

.table-of-contents-items-group {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  gap: 0.5rem;
}

.table-of-contents-item:hover {
  background-color: #f5f5f57b;
  cursor: pointer;
}

.team-viewer-dropdowns {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.team-viewer-button {
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #3765ebff;
}

.team-viewer-button:hover {
  cursor: pointer;
  border-bottom: 1px solid #1a3170ff;
}

.team-viewer-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.2rem;
  margin-left: 1rem;
}

p {
  margin-bottom: 0 !important;
}

.team-viewer-dropdown .team-viewer-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s ease-in-out;
}

.team-viewer-dropdown .team-viewer-content.open {
  max-height: 500px;
}

.team-viewer-experience {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

button {
  color: black;
}

.member-data-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}

.member-data-section-title {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 0;
}

.member-data-experience-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 0.75rem;
}

.member-data-experience {
  border: 1px solid rgba(186, 186, 186, 0.1);
  border-radius: 5px;
  box-shadow: 0 1px 2px 1px rgba(186, 186, 186, 0.1);
  padding: 0.5rem;
  min-height: 130px;
}

.member-data-title {
  font-weight: 500;
  margin-bottom: 0;
}

.member-data-piece-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.member-data-skills-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.member-data-skills {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.twitter-content-chart {
  width: 100%;
  height: 100%;
}

.twitter-content-title {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 0;
}

.twitter-content {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.twitter-content-item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.twitter-content-text {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.twitter-content-text-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.twitter-content-text-bold {
  font-weight: bold;
}
