.stepper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
    border-left: 1px solid #e0e0e0;
    padding-top: 10px;
  }
  
  .step {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    position: relative;
    padding-left: 15px;
    transition: 0.3s all ease-in-out;
  }
  
  
  .circle {
    width: 24px !important;
    height: 24px !important;
    border-radius: 50% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e0e0e0;
    margin-right: 15px;
    flex-shrink: 0;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    transition: 0.3s all ease-in-out;
  }
  
  .step.active .circle {
    background-color: #009688;
    transform: scale(1.1);
    color: #e0e0e0;
  }
  
  .title {
    font-size: 12px;
    font-weight: 500;
    color: #4a4a4a;
  }
  
  .step.active .title {
    color: #009688;
  }
  