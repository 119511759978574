//
// authentication.scss
//

.auth-body-bg {
  background-color: $card-bg;
}

.authentication-bg {
  background-image: url('../../../images/login-page-image.png');
  height: 100vh;
  background-size: cover;
  background-position: center;
  // .bg-overlay{
  //     background-color: #ffffff;
  // }

  @media (max-width: 991px) {
    display: none;
  }

  .content-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  h1 {
    font-size: 24px;
    /* Adjust the font size as needed */
    color: #fff;
    /* Text color */
    margin-top: 20px;
    /* Adjust the vertical spacing */
  }
}

.authentication-page-content {
  background-color: #ffffff;
  height: 100vh;
  display: flex;
}

.on-boarding-page {
  background-color: #f9fafb;
  height: 100%;
  border: 1px;
  border-radius: 10px;
  width: 90%;

  @media (min-width: 997px) {
    width: 90%;
  }
}

.calendy-iframe {
  width: 450px;
  height: 52vh;
  border: 1px solid grey;

  @media (max-width: 768px) {
    width: -webkit-fill-available !important;
    height: 52vh;
  }
}

.on-boarding-step {
  background-color: #ffffff;
  height: 100vh;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border: 1px;
  border-radius: 0px 10px 10px 0px;
}

.stepper-label {
  font-weight: 700 !important;
  font-size: 16px !important;
}

.auth-form-group-custom {
  position: relative;

  .form-control {
    height: 60px;
    padding-top: 28px;
    padding-left: 60px;
  }

  label {
    position: absolute;
    top: 7px;
    left: 60px;
  }

  .auti-custom-input-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 19px;
    font-size: 24px;
    color: $primary;
  }
}

// authentication home icon
.home-btn {
  position: absolute;
  top: 15px;
  right: 25px;
  z-index: 9;
}

.application-card-border {
  border: 1px solid #ebebeb;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 6px;
}

.exchange-card-border {
  border: 1px solid #efefef;
  padding: 5px;
  background: #fff;
  margin-bottom: 10px;
  border-radius: 4px;

  // @media (max-width: 512px) {
  //   max-height: 550px;
  //   overflow-y: auto;
  //   padding-bottom: 10px;
  // }
}

.customization-card-border {
  border: 1px solid #efefef;
  padding: 5px;
  background: #fff;
  margin-bottom: 10px;
  border-radius: 10px;

  // @media (max-width: 512px) {
  //   max-height: 550px;
  //   overflow-y: auto;
  //   padding-bottom: 10px;
  // }
}

.text-grey {
  color: #bbbbbb;
}

.circle {
  width: 50px !important;
  height: 50px !important;
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid #e3e3e3;
  padding: 0px;
  margin: 0px;
}

.dark {
  width: 50px !important;
  height: 50px !important;
  background-color: #1d211e;
  border-radius: 50%;
  padding: 0px;
  margin: 0px;
}

.color-picker-class {
  width: '50px';
  height: '50px';
  border-radius: '50%';
  cursor: 'pointer';
  border: 1px solid #e3e3e3;
}

pre {
  background: #eee;
  padding: 1rem;
  overflow: auto;
  border-radius: 5px;
}

pre code {
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
}

.full-screen-modal .modal-content {
  min-width: -webkit-fill-available;
  margin: 0;
  border-radius: 0;
}
