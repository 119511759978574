@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins/breakpoints';

@each $value in ('354px', '550px', '582px', '650px', '620px') {
  .height-#{$value} {
    height: #{$value} !important;
  }
  .height-md-#{$value} {
    @include media-breakpoint-up(md) {
      height: #{$value} !important;
    }
  }
}

.supported-payment-options-slider {
  .slide {
    @include media-breakpoint-up(md) {
      min-width: 280px;
      min-height: 250px;
      margin-right: 15px;
    }
  }
}

.hover\:text-dark {
  &:hover {
    color: $dark !important;
  }
}
