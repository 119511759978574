.application-frame {
  width: 324px;
  height: 400px;
  background: #ffffff;
  border: 2px solid rgba(24, 27, 48, 0.6);
  border-radius: 16px;
  margin-top: 186px;

  p {
    width: 216px;
    height: 72px;
    margin-left: 54px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #000000;
  }
}

.account-type-frame {
  width: 128px;
  height: 132px;
  background: #ffffff;
  border: 1px solid rgba(24, 27, 48, 0.6);
  border-radius: 10px;

  p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color: #000000;
    padding-bottom: 10px;
  }
}

.selected-border {
  border: 1px solid #77b3d8;
  border-radius: 10px;
}

.shadow-effect {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.select-status {
  background: #ffffff;
  border: 2px solid rgba(24, 27, 48, 0.6);
  border-radius: 16px;

  &.selected {
    color: #ffffff;
    background: #000000;
  }

  p {
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.file-upload-wrapper {
  height: 47px;
  width: 100%;
  min-width: 255px;
  display: flex;
  align-items: center;
}

.border-danger {
  .css-13cymwt-control {
    border-color: #ff3d60 !important;
  }
}

.str-chat {
  --str-chat__primary-color: #009688;
  --str-chat__active-primary-color: #004d40;
  --str-chat__surface-color: #f5f5f5;
  --str-chat__secondary-surface-color: #fafafa;
  --str-chat__primary-surface-color: #e0f2f1;
  --str-chat__primary-surface-color-low-emphasis: #edf7f7;
  --str-chat__border-radius-circle: 6px;
  height: 77vh;
}

.str-chat.messaging.light.str-chat-channel-list.str-chat__channel-list.str-chat__channel-list-react {
  @media (max-width: 960px) {
    background: white;
    position: absolute !important;
  }

  min-height: 77vh !important;
}

.str-chat__channel-list-messenger.str-chat__channel-list-messenger-react {
  @media (max-width: 960px) {
    background: white;
  }
}

.notification-banner {
  background-color: #dfa941;
  color: white;
  padding: 10px 20px;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.notification-banner p {
  margin: 0;
  font-size: 16px;
}

.notification-banner-button {
  background-color: #f8e71c;
  color: #333;
  border: none;
  padding: 5px 10px;
  margin-left: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.notification-banner-button:hover {
  background-color: #f7cf0a;
}
