.clickable-label {
    overflow: hidden;
    position: relative;
    display: inline-block;
}

.clickable-label::before,
.clickable-label::after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
}

.clickable-label::before {
    background-color: #007BFF !important;
    height: 2px;
    bottom: 0;
    transform-origin: 100% 50%;
    transform: scaleX(0);
    transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
}

.clickable-label::after {
    content: attr(data-replace);
    height: 100%;
    top: 0;
    transform-origin: 100% 50%;
    transform: translate3d(200%, 0, 0);
    transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
    color: #007BFF !important;
}

.clickable-label:hover::before {
    transform-origin: 0% 50%;
    transform: scaleX(1);
}

.clickable-label:hover::after {
    transform: translate3d(0, 0, 0);
}

.clickable-label span {
    display: inline-block;
    transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
}

.clickable-label:hover span {
    transform: translate3d(-200%, 0, 0);
}

.levitate-input {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Initial shadow for a subtle effect */
}

.levitate-input:hover {
    transform: translateY(-5px); /* This will move the input up by 5px */
    box-shadow: 0 4px 12px rgba(0,0,0,0.2); /* Increase shadow on hover for a lifting effect */
}
