.collapse{
    &-box-wrapper{
    border: 1px solid;
    // border-color: #0074d9;
    // border-color: $primary;
    
    border-radius: 4px;
    padding: 1rem;
    background: #edf4fa;
    // background: #5665d215;
    margin: 10px 0;
}
&-shadow{
    -webkit-box-shadow: 0 0 0 2px rgba(40, 72, 229, 0.2); 
    box-shadow: 0 0 0 2px rgba(40, 72, 229, 0.2);
    // -webkit-box-shadow: 0 0 0 2px #5665d250; 
    // box-shadow: 0 0 0 2px #5665d275;
}
&-title{
    border-bottom: 2px solid #e5e2e2 ;
    display: flex;
    justify-content: space-between;
    .right-side{

    }
   .dropDown-btn {    
        display: flex;
        width: 25px;
        height: 25px;
        border: 1px solid #00488898;
        &:hover{
            -webkit-box-shadow: 0 0 0 2px rgba(40, 72, 229, 0.2); 
            box-shadow: 0 0 0 2px rgba(40, 72, 229, 0.2);
        }
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background-color: #edf4fa;
        p{
            padding-top: 4px;
            padding-left: .5px;
        }
    }
}
}
.arrow {
    border: 2px solid #004888;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 2.5px;
  }
.down{
    transform: rotate(45deg);
    transition: transform .3s linear;
  }
  
  .up{
    transform: rotate(225deg);
    transition: transform .3s linear;
  }
