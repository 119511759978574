/* StreamChat CSS */

/* General Layout */
.chat-container {
    display: flex;
    height: calc(60vh - 50px); /* Adjust the total height of the chat container */
  }
  
  /* Channel List Styling */
  .str-chat__sidebar {
    min-width: 250px;
    max-height: 60%; /* Takes the full height of its parent container */
    overflow-y: auto;
  }
  
  /* Channel Header Styling */
  .str-chat__header {
    background: #333;
    color: white;
    padding: 15px;
    font-size: 16px;
    font-weight: bold;
    height: 60px; /* Fixed height for the header */
  }
  
  /* Virtualized Message List Styling */
  .str-chat__virtual-list {
    flex-grow: 1; /* Takes up all available space */
    overflow-y: auto;
    background-color: #f9f9f9;
    padding: 10px;
  }
  
  .str-chat__message-simple {
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 15px;
  }
  
  .str-chat__avatar {
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }
  
  /* Message Input Styling */
  .str-chat__input-flat {
    background-color: #fff;
    padding: 10px;
    border-top: 1px solid #ebebeb;
    height: 70px; /* Fixed height for the message input area */
  }
  
  .str-chat__input-flat input {
    border: 1px solid #ddd;
  }
  
  /* Thread Styling */
  .str-chat__thread {
    width: 320px;
    border-left: 1px solid #ebebeb;
    height: 60%; /* Full height of its parent container */
  }
  
  /* Scrollbar Styling for Webkit Browsers */
  .str-chat__main-panel::-webkit-scrollbar {
    width: 6px;
  }
  
  .str-chat__main-panel::-webkit-scrollbar-track {
    background: #f0f0f0;
  }
  
  .str-chat__main-panel::-webkit-scrollbar-thumb {
    background: #c2c2c2;
  }
  
  .str-chat__main-panel::-webkit-scrollbar-thumb:hover {
    background: #a0a0a0;
  }
  
  /* Responsiveness */
  @media (max-width: 768px) {
    .chat-container {
      flex-direction: column;
    }
  
    .str-chat__sidebar {
      min-width: 100%;
      max-height: 150px;
      overflow-x: hidden;
    }
  
    .str-chat__thread {
      display: none;
    }
  }
  