.run-flow-response ol>li {
  list-style-type: decimal;
}

.run-flow-response ul>li {
  list-style-type: disc;
}

.run-flow-response ul {
  padding-left: 2rem !important;
}

.run-flow-response {
  /* background-color: #f0f0f0 !important; */
  color: #2f2f2f !important;
  border: 1px solid;
}

.rounded-2 {
  border-radius: 0.75rem !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 10px;
  padding: 10px;

}
::-webkit-scrollbar-thumb {
  border-radius: 18px;
  background: #c2c9d2;
  padding: 10px;
}